import Image from "next/image";
import Link from "next/link";

import CourseDetails from "../../data/course-details/courseData.json";
import useCategoryCount from "@/context/useCategoryCount";

const CategoryOne = () => {
  const { categoryCounts } = useCategoryCount(CourseDetails.courseDetails);

  function pointerEventsHandler(category) {
    if (category === "Full Stack"  || category === "Back End" || category === "Mobile Application")
      return "none";

    return "";
  }
  return (
    <>
      {CourseDetails &&
        CourseDetails.courseDetails.slice(0, 4).map((item, innerIndex) => {
          const count = categoryCounts[item.category] || 0;

          return (
            <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={innerIndex}>
              <Link
                className="rbt-cat-box rbt-cat-box-1 text-center"
                style={{
                  pointerEvents: `${pointerEventsHandler(item.category)}`,
                }}
                href={
                  item.category === "Full Stack" ||
                  item.category === "Back End"
                    ? "/"
                    : `/course-filter-one-toggle`
                }
              >
                <div className="inner">
                  <div className="icons">
                    <Image
                      src={item.cateSmallImg}
                      width={80}
                      height={80}
                      priority
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h5 className="title">{item.category}</h5>
                    <div className="read-more-btn">
                      <span className="rbt-btn-link">
                        {item.category === "Full Stack" ||
                        item.category === "Back End" ||
                        item.category === "Mobile Application"
                          ? 0
                          : count}{" "}
                        Course{count !== 1 ? "s" : ""}
                        <i className="feather-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
    </>
  );
};

export default CategoryOne;
